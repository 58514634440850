import React from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoImage from './acca.png'; // Import the image

const Invoice = () => {
  const location = useLocation();

const downloadPDF = () => {
  const pdf = new jsPDF();

  const logoWidth = 30; // Adjust as needed
  const logoHeight = 30; // Adjust as needed
  const logoX = pdf.internal.pageSize.getWidth() - 40; // Adjust as needed
  const logoY = 10; // Adjust as needed

  
  // Add logo
  const imgData = logoImage;
  pdf.addImage(imgData, 'JPEG', logoX, logoY, logoWidth, logoHeight);

  // Add academy name, professional coaching description, and invoice number
  pdf.setFontSize(16);
  pdf.text('The Goodwill Academy', 15, 20);

  pdf.setFontSize(12);
  pdf.text('Professional Coaching Online', 15, 30);
  pdf.text(`Invoice No: ${location.state.data.invoice}`, 15, 40);

  // Add customer name, date, and address
  pdf.text(`Customer Name: ${location.state.data.name}`, 15, 60);
  pdf.text(`Invoice Date: ${new Date(location.state.data.subscribed_date.seconds * 1000).toDateString()}`, 15, 70);
  // You can add address here as well if available

  // Generate table
  pdf.autoTable({ html: '#invoice-table', startY: 80 });

  // Add subtotal and total
  const totalPrice =
    location.state.data.currency === "INR"
      ? "₹" + location.state.data.purchased_price
      : "$" + location.state.data.purchased_price;
  const subTotal =
    location.state.data.currency === "INR"
      ? "₹" + location.state.data.purchased_price
      : "$" + location.state.data.purchased_price;
  const subtotalText = `Subtotal: ${subTotal}`;
  const totalText = `Total: ${totalPrice}`;
  const textWidth = pdf.getTextWidth(totalText); // Get width of total text

  const subtotalY = pdf.lastAutoTable.finalY + 10;
  const totalY = subtotalY + 10; // Set total Y position

  // Align subtotal and total
  const subtotalX = 152;
  const totalX = 170 - textWidth; // Adjust total X position based on text width

  pdf.text(subtotalText, subtotalX, subtotalY);
  pdf.text(totalText, totalX, totalY);

  // Add disclaimer at the bottom of the page
  pdf.text('This invoice is system generated hence doesnt require signature stamping', 35, 250);
  const disclaimerText = " ";
  const disclaimerWidth = pdf.getStringUnitWidth(disclaimerText) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
  const disclaimerX = (pdf.internal.pageSize.getWidth() - disclaimerWidth) / 2;
  const disclaimerY = pdf.internal.pageSize.getHeight() - 10;
  pdf.text(disclaimerText, disclaimerX, disclaimerY, { align: 'center' });
  const date = new Date();
  pdf.save(date.toLocaleDateString()+"GWA-Invoice");
};





  return (
    <div className="flex justify-center items-center mt-12">
      <div className="bg-blue-200 font-sans leading-normal w-1/2 tracking-normal">
        <div className="bg-blue-300">
          <div className="container mx-auto px-4">
            <div className="flex justify-between items-center py-4">
              <div>
                <h1 className="text-xl font-bold">The Goodwill Academy</h1>
                <p className="text-sm text-gray-600">Professional Coaching Online</p>
              </div>
              <div>
                <h1 className="text-xl font-bold">Invoice No</h1>
                <p className="text-gray-600">{location.state.data.invoice}</p>
              </div>
            </div>
          </div>
        </div>

        <InvoiceSection location={location} />

        <div className="p-4">
          <div className="flex justify-end">
            <button className=" text-black font-bold py-2 px-4 rounded" onClick={downloadPDF}>
              Download PDF
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const InvoiceSection = ({ location }) => (
  <div className="container mx-auto mt-8">
    <div className="bg-white shadow-md rounded-lg">
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">Invoice Details</h2>
        <div className="flex justify-between mt-2">
          <div>
            <p className="text-sm text-gray-600">Invoice Date</p>
            <p className="font-semibold">
              {new Date(
                location.state.data.subscribed_date.seconds * 1000
              ).toDateString()}
            </p>
          </div>

          <div>
            <p className="text-sm text-gray-600">Total Amount</p>
            <p className="font-semibold text-green-600">
              {location.state.data.currency === "India" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
      </div>
      <div className="p-4 border-b">
        <h2 className="text-lg font-semibold">Customer Details</h2>
        <div className="mt-2">
          <p className="text-sm text-gray-600">Customer Name</p>
          <p className="font-semibold">{location.state.data.name}</p>
        </div>
      </div>
      <div className="p-4">
        <h2 className="text-lg font-semibold">Items</h2>
        <div className="mt-2">
          <table id="invoice-table" className="w-full">
            <thead>
              <tr className="border-b">
                <th className="pb-2 text-left text-sm font-semibold text-gray-600">
                  Book Name
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Quantity
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Price
                </th>
                <th className="pb-2 text-right text-sm font-semibold text-gray-600">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {location.state.data.book_details &&
                location.state.data.book_details.map((item, index) => (
                  <tr className="border-b" key={index}>
                    <td className="py-2 text-right">{item.book_name}</td>
                    <td className="py-2 text-right">1</td>
                    <td className="py-2 text-right">{item.offer_price}</td>
                    <td className="py-2 text-right">{item.offer_price}</td>
                  </tr>
                ))}
              {!location.state.data.book_details && (
                <tr className="border-b" key={1}>
                  <td className="py-2 text-right">
                    {location.state.data.package.toUpperCase()}
                  </td>
                  <td className="py-2 text-right">1</td>
                  <td className="py-2 text-right">
                    {location.state.data.purchased_price}
                  </td>
                  <td className="py-2 text-right">
                    {location.state.data.purchased_price}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-end">
          <div className="text-right">
            <p className="text-sm text-gray-600">Subtotal</p>
            <p className="font-semibold">
              {location.state.data.currency === "INR" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <div className="text-right">
            <p className="text-sm text-gray-600">Total</p>
            <p className="font-semibold">
              {location.state.data.currency === "INR" ? "₹" : "$"}
              {location.state.data.purchased_price}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Invoice;
