import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useState } from "react";
import User from "../Auth/User";
import { db } from "../Config/Config";
import UserData from "../Config/UserData";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

export default function Passed() {
  const uid = User();
  const user = UserData(uid);
  //============================================ Variable Decleration ========================================//
  const [isModalOpen, setModalOpen] = useState(false);
  const closeModal = () => setModalOpen(false);
  const [file,setfile] = useState();
  const [open, setOpen] = React.useState(false);
  const [toastType, settoastType] = useState("success");
  const [toastContent, settoastContent] = useState("Logged In");
  const [doc_id,setdoc_id] = useState('')

    const handleClick = (type, content) => {
      setOpen(true);
      settoastType(type);
      settoastContent(content);
    };
  //============================================ Fetching dropdown datas ========================================//
  const [values, setvalues] = useState([]);
  const [rank, setrank] = useState();
  const fetchDropbox = async () => {
    setvalues([]);
    const q = collection(db, "cashback_dropbox");
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setvalues((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  useState(() => {
    fetchDropbox();
  });
  const dropBoxhandler = async (e) => {
    const personNamedBob = values.find(
      (person) => person.id === e.target.value
    );
    setrank(personNamedBob);
    setdoc_id(e.target.value);
  };
  //============================================ Upload Datas ========================================//

    const uploadFile = async (e) => {
      e.preventDefault();
      const date = new Date();
      const storage = getStorage();
      if (file) {
        const storageRef = ref(storage, "resume/" + date);
        uploadBytes(storageRef, file).then((snapshot) => {
          getDownloadURL(ref(storageRef)).then((url) => {
            uploadData(url);
          });
        });
      } else {
        handleClick("error", "Please upload document");
      }
    };
  const uploadData=async(url)=>{
    const date =new Date();
    await addDoc(
      collection(db, "cashback_applications"),
      user,
      { merge: true }
    ).then(async(res)=>{
      await updateDoc(doc(db, "cashback_applications",res.id), {
            url: url,
            discount_date: date,
            status: "pending",
            cashback_doc_id: doc_id

          }).then(() => {
            handleClick(
              "success",
              "Your application has been submitted successfully."
            );
            setModalOpen(false);
            setrank("");
            setfile();
            setdoc_id('');
          });
    });
    
   
  }
 const Alert = React.forwardRef(function Alert(props, ref) {
   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });
   const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
  }
  window.addEventListener("scollerEvent", (event) => {
    if (event.type === "scollerEvent") {
      setModalOpen(localStorage.getItem("scolr_flag"));
    }
  });
  return (
    <div>
      <Stack spacing={2} sx={{ width: "80%" }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={toastType}
            sx={{ width: "100%" }}
          >
            {toastContent}
          </Alert>
        </Snackbar>
      </Stack>
      
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60">
          <div className="bg-white p-8 rounded-md">
            <p class="mb-5 text-2xl font-medium">Apply Now</p>
            <p class="mb-6 text-sm">
              Unlock a discount of up to 100% for your online ACCA education.
            </p>

            <div class="mb-6">
              <div class="relative mb-3 flex overflow-hidden border-b-2 transition">
                <select
                  onChange={(e) => {
                    dropBoxhandler(e);
                  }}
                  id="status"
                  class="w-full appearance-none border-blue-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                >
                  <option value="" disabled selected hidden>
                    Pick Your Status
                  </option>

                  {values.map((doc, index) => {
                    return <option value={doc.id}>{doc.data.content} </option>;
                  })}
                </select>
              </div>
              <div class="focus-within:border-b-blue-500 relative mb-3 flex overflow-hidden border-b-2 transition">
                <input
                  type="text"
                  id="password"
                  value={rank ? rank.data.discount : null}
                  disabled={true}
                  class="w-full flex-1 appearance-none border-blue-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                  placeholder="Discount"
                />
              </div>
              <label className="mr-8">Upload Your Certificate</label>
              <div class="focus-within:border-b-blue-500 relative mb-5 flex overflow-hidden border-b-2 transition">
                <input
                  type="file"
                  id="password"
                  class="w-full flex-1 appearance-none border-blue-300 bg-white px-4 py-2 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                  placeholder="Discount"
                  onChange={(e) => setfile(e.target.files[0])}
                />
              </div>
            </div>

            <button
              onClick={closeModal}
              class="mb-6 rounded-xl bg-gray-600 px-8 py-3 font-medium text-white hover:bg-gray-800"
            >
              close
            </button>
            <button
              onClick={uploadFile}
              class="mb-6 ml-4 rounded-xl bg-blue-600 px-8 py-3 font-medium text-white hover:bg-blue-700"
            >
              Submit application
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
