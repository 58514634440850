import { getAuth } from "firebase/auth";
import {  addDoc, collection, doc, getCountFromServer, getDoc, query, updateDoc, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useState, useEffect } from "react";
import {  useLocation, useNavigate } from "react-router-dom";
import Spinner from "../assets/Loading";
import { app, db } from "../Config/Config";
import './css/radio.css'
export default function Exams() {
  const location = useLocation();
    const [loading, setloading] = useState(true);

  const navigate = useNavigate();
  const [sbtFlag, setsbtFlag] = useState(false);
  const [timer, setTimer] = useState(
    parseInt(location.state.data.exam_duration)
  );
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setsbtFlag(true);
      handleSubmit();
    }
    return () => clearInterval(interval);
  }, [timer]);
  // questions length

  useEffect(() => {
    // Reference to your Firestore collection
  }, []);
  const documentLength = async (ref) => {
    const snapshot = await getCountFromServer(ref);
   const randomNumbers = getRandomNumbersWithoutRepetition(
     (parseInt(location.state.data.question_limit)-1),
     snapshot.count
   );

   pickQuestions(randomNumbers);
  };
// random question numbers

function getRandomNumbersWithoutRepetition(limit, count) {
  if (count > limit + 1) {
    console.error("Not enough unique numbers in the range.");
    return [];
  }

  const numbers = [...Array(limit + 1).keys()];
  const shuffledNumbers = [];

  // Perform a Fisher-Yates shuffle
  for (let i = numbers.length - 1; i >= 0; i--) {
    const randomIndex = Math.floor(Math.random() * (i + 1));
    shuffledNumbers.push(numbers[randomIndex]);
    numbers.splice(randomIndex, 1);
  }

  // Take the first 'count' shuffled numbers
  return shuffledNumbers.slice(0, count);
}

  // questioin picking
  const [QuestionsList, setQuestionsList] = useState([]);
  const pickQuestions = async (random) => {
    setloading(false);
    for (var i = 0;i <= random.length ;i++) {
      const doc_id = (parseInt(random[i]) + 10001).toString();
      const docRef = doc(
        db,
        "exams",
        location.state.data.book_id,
        location.state.data.chapter_id,
        doc_id
        
      );
         var docSnap = await getDoc(docRef);

         if (docSnap.exists()) {
                   setQuestionsList((prev) => {
               return [
                 ...prev,
                 {
                   id: doc_id,
                   data: docSnap.data(),
                 },
               ];
             });     
         
       } else {
         console.log("No such document!");
       }
    }
  };

  // function call
  const functions = getFunctions(app, "asia-south1");
  //payment
  const resultFunction = (right, total_questions, unattended, wrong) => {
    const date= new Date();
    setloading(true);
    const examResult = httpsCallable(functions, "examResult");
    examResult({
      book_id: location.state.data.book_id,
      chapter_id: location.state.data.chapter_id,
      chapter_name: location.state.data.chapter_name,
      desc: location.state.data.chapter_name,
      book_name: location.state.book_name,
      name_of_user: userData.name,
      right_answers: right,
      total_questions: total_questions,
      uid: userId,
      unattended_questions: unattended,
      wrong_answers: wrong,
      platform: "web",
    }).then(async(result) => {
      const data = result.data;
      if (data) {
        await addDoc(collection(db,"exam_answers",userId,"answers"),
          {QuestionsList}
        ).then(async (res)=>{
          await updateDoc(doc(db, "exam_answers", userId, "answers", res.id), {
            date: date,
            book_id: location.state.data.book_id,
            chapter_id: location.state.data.chapter_id,
            chapter_name: location.state.data.chapter_name,
            desc: location.state.data.chapter_name,
            book_name: location.state.book_name,
          }).then(()=>{
                navigate("/examresult", {
                  state: {
                    data: QuestionsList,
                    marks: right,
                    unatt: unattended,
                    wrong: wrong,
                  },
                });
          });
        });
    
        
      } else {
        alert("Something went wrong!!");
      }
    });
  };

  useState(() => {
    const collectionRef = collection(
      db,
      "exams",
      location.state.data.book_id,
      location.state.data.chapter_id
    );
    const q = query(collectionRef, where("status", "==", true));

    documentLength(q);
  });

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const timerColor = timer <= 30 ? "text-red-500" : "text-green-800";
  const handleSubmit = () => {
    var total = 0;
    var unattended = 0;
    var wrong = 0;
    for (var i = 0; i < QuestionsList.length; i++) {
      if (!QuestionsList[i].ans) {
        unattended = unattended + 1;
      } else {
        if (QuestionsList[i].ans === QuestionsList[i].data.answer) {
          total = total + 1;
        } else {
          wrong = wrong + 1;
        }
      }
    }
    if (i === QuestionsList.length) {
      resultFunction(total, QuestionsList.length, unattended, wrong);
    }
  };

  // fetching user
  const [userData, setuserData] = useState();

  const fetchUser = async (uid) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setuserData(docSnap.data());
    } else {
      setuserData("");
    }
  };
  const [userId, setuserId] = useState(null);
  // click function
  const pageClick = (uid) => {
    const pageClick = httpsCallable(functions, "pageClick");
    pageClick({
      uid: uid,
      page_name: "exam_page",
    }).then((result) => {
      // const data = result.data;
      // if (data) {
      //   console.log("done")
      // } else {
      //   alert("Something went wrong!!");
      // }
    });
  };
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setuserId(user.uid);
    fetchUser(user.uid);
    pageClick(user.uid);
  }, []);

  const updateObject = (targetId, updatedValue, answer, ans) => {
    const updatedArray = QuestionsList.map((obj) =>
      obj.id === targetId ? { ...obj, ans: updatedValue } : obj
    );

    setQuestionsList(updatedArray);
  };
  const [option, setoption] = useState("");
  const [currentQuestion, setcurrentQuestion] = useState(0);

  const handleNext = () => {
    setcurrentQuestion(currentQuestion + 1);
    setoption("");
  };
  return (
    <>
      {!loading ? (
        <div>
          <div class="  top-2 right-0 flex items-end justify-end mr-20 mt-2  sm:mr-10 sm:mt-50 sm:mb-10">
            <div class="w-20 h-20 relative">
              <div class="border border-gray-500  bg-white rounded-full w-full h-full absolute"></div>
              <div class="rounded-full w-full h-full absolute animate-timer-rotate border-4 border-solid border-gray-400 border-t-0"></div>
              <div class="flex items-center justify-center rounded-full absolute top-0 left-0 w-full h-full">
                <div class={`text-2xl font-bold ${timerColor}`} id="timer">
                  {formatTime(timer)}
                </div>
              </div>
            </div>
          </div>

          {QuestionsList.length ? (
            <>
              {QuestionsList.map((doc, index) => {
                return (
                  <>
                    {index === currentQuestion ? (
                      <div class="container  mx-auto py-8 ">
                        <div class="max-w-6xl mx-auto bg-white shadow-xl rounded-lg p-8">
                          <h2 class="text-xl font-semibold mb-4">
                            Question &nbsp;{index + 1}:
                          </h2>
                          <p class="text-gray-600 mb-6 xl:mr-10 sm:mr-5">
                            {doc.data.question}
                          </p>
                          {doc.ans ? (
                            <ul class="space-y-2">
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-1"
                                  name="option"
                                  value={doc.data.a}
                                  checked={doc.ans === doc.data.a}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.a,
                                      doc.data.answer,
                                      doc.data.a
                                    );
                                    setoption(doc.data.a);
                                  }}
                                />
                                <label
                                  for="option-1"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option A : {doc.data.a}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-2"
                                  name="option"
                                  value={doc.data.b}
                                  checked={doc.ans === doc.data.b}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.b,
                                      doc.data.answer,
                                      doc.data.b
                                    );
                                    setoption(doc.data.b);
                                  }}
                                />
                                <label
                                  for="option-2"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option B : {doc.data.b}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-3"
                                  name="option"
                                  value={doc.data.c}
                                  checked={doc.ans === doc.data.c}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.c,
                                      doc.data.answer,
                                      doc.data.c
                                    );
                                    setoption(doc.data.c);
                                  }}
                                />
                                <label
                                  for="option-3"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option C : {doc.data.c}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-4"
                                  name="option"
                                  value={doc.data.d}
                                  checked={doc.ans === doc.data.d}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.d,
                                      doc.data.answer,
                                      doc.data.d
                                    );
                                    setoption(doc.data.d);
                                  }}
                                />
                                <label
                                  for="option-4"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option D : {doc.data.d}
                                </label>
                              </li>
                            </ul>
                          ) : (
                            <ul class="space-y-2">
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-1"
                                  name="option"
                                  value={doc.data.a}
                                  checked={option === doc.data.a}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.a,
                                      doc.data.answer,
                                      doc.ans
                                    );
                                    setoption(doc.data.a);
                                  }}
                                />
                                <label
                                  for="option-1"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option A : {doc.data.a}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-2"
                                  name="option"
                                  value={doc.data.b}
                                  checked={option === doc.data.b}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.b,
                                      doc.data.answer,
                                      doc.ans
                                    );
                                    setoption(doc.data.b);
                                  }}
                                />
                                <label
                                  for="option-2"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option B : {doc.data.b}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-3"
                                  name="option"
                                  value={doc.data.c}
                                  checked={option === doc.data.c}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.c,
                                      doc.data.answer,
                                      doc.ans
                                    );
                                    setoption(doc.data.c);
                                  }}
                                />
                                <label
                                  for="option-3"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option C : {doc.data.c}
                                </label>
                              </li>
                              <li class="flex items-center">
                                <input
                                  type="radio"
                                  id="option-4"
                                  name="option"
                                  value={doc.data.d}
                                  checked={option === doc.data.d}
                                  class="hidden"
                                  onChange={(e) => {
                                    updateObject(
                                      doc.id,
                                      doc.data.d,
                                      doc.data.answer,
                                      doc.ans
                                    );
                                    setoption(doc.data.d);
                                  }}
                                />
                                <label
                                  for="option-4"
                                  class="button-label"
                                  tabindex="1"
                                >
                                  Option D : {doc.data.d}
                                </label>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    ) : null}
                  </>
                );
              })}
              <div class="flex justify-center mx-w-200">
                <div className="flex justify-between mt-4 text-bold">
                  {currentQuestion > 0 ? (
                    <button
                      title="Previous"
                      type="button"
                      class="inline-flex items-center justify-center w-auto px-4 py-2 bg-gray-300 text-gray-700 font-semibold rounded-md shadow hover:bg-gray-400"
                      onClick={() => {
                        setcurrentQuestion(currentQuestion - 1);
                      }}
                    >
                      Previous
                    </button>
                  ) : null}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {currentQuestion ===
                  parseInt(location.state.data.question_limit) - 1 ? null : (
                    <button
                      title="Next"
                      type="button"
                      class="inline-flex items-center justify-center w-auto px-4 py-2 bg-black text-white font-semibold rounded-md shadow hover:bg-black"
                      onClick={() => {
                        handleNext();
                      }}
                    >
                      Next
                    </button>
                  )}

                   {currentQuestion ===
                parseInt(location.state.data.question_limit) - 1 ? (
                  <button
                    disabled={sbtFlag}
                    onClick={handleSubmit}
                    class=" w-full max-w-xs px-4  font-bold shadow-sm rounded-lg py-3 bg-gray-800  text-white flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-sm focus:shadow-outline"
                  >
                    Submit
                  </button>
                ) : null}
                </div>
              </div>
              <div class="mt-6 flex justify-center mb-6">
               
              </div>
            </>
          ) : null}
        </div>
      ) : <Spinner/>}
    </>
  );
}
