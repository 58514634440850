import React, { useState, useEffect, useRef } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { app, db } from "../Config/Config";
import { getCountries } from "country-state-picker";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { doc, getDoc } from "firebase/firestore";
export default function Login() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpCode, setOTPCode] = useState("");
  const [seconds, setSeconds] = useState(59);
  const [timerFlag, settimerFlag] = useState(true);

  const auth = getAuth(app);

  //======================================= Google Authprovider ==========================================//
  const provider = new GoogleAuthProvider();

  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
      const docRef = doc(db, "users", result.user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        localStorage.setItem("login_popup", false);
        setIsModalOpen(false);
        handleClick("success", "Logged In");

      } else {
        localStorage.setItem("login_popup", false);
        setIsModalOpen(false);
        const cartEvent = new Event("loginReg");
        window.dispatchEvent(cartEvent);
        handleClick("success", "Logged In");
      }
      })
      .catch((error) => {
        handleClick("error", "Something went wrong...Try again");
      });
  };
  //======================================= Phone Authentication ==========================================//
  const [smsFlag, setsmsFlag] = useState(false);
  // Sending OTP

  const [Confirmationresult, setconfirmationresult] = useState(null);
  const onSignInSubmit = (e) => {
    e.preventDefault();
    if (validationError) {
      startTimer();
      setsmsFlag(true);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-widget",
        {
          size: "invisible",
          callback: (response) => {},
          defaultCountry: "IN",
        },
        auth
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });

      const number = countryCode + phoneNumber;
      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, number, appVerifier)
        .then((confirmationResult) => {
          setconfirmationresult(confirmationResult);
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          window.grecaptcha.reset(window.recaptchaWidgetId);
        });
    } else {
      alert("Enter a valid Mobile Number");
    }
  };

  //======================================= Phone code verification ==========================================//
  const verify = (e) => {
    e.preventDefault();
         
    window.confirmationResult
      .confirm(otpCode)
      .then(async(result) => {
          const docRef = doc(db, "users", result.user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        localStorage.setItem("login_popup", false);
        setIsModalOpen(false);
        handleClick("success", "Logged In");

      } else {
        localStorage.setItem("login_popup", false);
        setIsModalOpen(false);
        const cartEvent = new Event("loginReg");
        window.dispatchEvent(cartEvent);
        handleClick("success", "Logged In");
      }
      })
      .catch((error) => {
        handleClick("error", "Something went wrong...Try again");
      });
  };
  //======================================= re-captcha reset ==========================================//
  const handleSignOut = () => {
    if (auth.currentUser) {
      auth
        .signOut()
        .then(() => {
          handleResendCode();
        })
        .catch((error) => {
          handleClick("error", "Something went wrong...Try again");
        });
    } else {
      handleResendCode();
    }
  };
  //======================================= re-send Code ==========================================//
  const handleResendCode = () => {
    settimerFlag(false);

    if (Confirmationresult) {
      const number = countryCode + phoneNumber;
      const appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, number, appVerifier)
        .then((confirmationResult) => {
          setconfirmationresult(confirmationResult);
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          handleClick("error", "Something went wrong...Try again");
        });
    }
  };
  //======================================= Country Code ==========================================//
  const [countryCodeList, setcountryCodeList] = useState([]);
  const [countryCode, setcountryCode] = useState("+91");
  useState(() => {
    const countries = getCountries();
    setcountryCodeList(countries);
  });

  const [open, setOpen] = React.useState(false);
  const [toastType, settoastType] = useState("success");
  const [toastContent, settoastContent] = useState("Logged In");
  const handleClick = (type, content) => {
    setOpen(true);
    settoastType(type);
    settoastContent(content);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  //======================================= Timer ==========================================//
  const intervalRef = useRef();
  const startTimer = () => {
    setSeconds(59);
    intervalRef.current = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  };

  const stopTimer = () => {
    clearTimeout(intervalRef.current);
  };

  useEffect(() => {
    if (seconds + 1 === 0) {
      stopTimer();
      settimerFlag(false);
    }
  }, [seconds]);
  const [color, setcolor] = useState("grey");
  const [font, setfont] = useState("lighter");
  useEffect(() => {
    if (timerFlag) {
      setcolor("grey");
      setfont("lighter");
    } else {
      setcolor("black");
      setfont("bold");
    }
  }, [timerFlag]);
  //======================================= Phone number verification ==========================================//
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    if (countryCode === "+91") {
      const indianMobileNumberRegex = /^[6789]\d{9}$/;
      setValidationError(indianMobileNumberRegex.test(phoneNumber));
    } else {
      setValidationError(true);
    }
  }, [phoneNumber, countryCode]);



  //======================================= PopUP controls ==========================================//
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
      localStorage.setItem("login_popup", false);
      setIsModalOpen(false);
    };

  window.addEventListener("storage", (event) => {
    if (event.type === "storage") {
        setIsModalOpen(localStorage.getItem("login_popup"));
    }
  });

  return (
    <div>
          <Stack spacing={2} sx={{ width: "80%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity={toastType}
                sx={{ width: "100%" }}
              >
                {toastContent}
              </Alert>
            </Snackbar>
          </Stack>
          <div>
            {isModalOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center backdrop-filter backdrop-blur-lg mt-12">
                <div className="bg-white p-8 rounded-lg max-w-xl">
                  <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-semibold md:leading-tight md:text-left md:text-2xl mt-2s">
                      Welcome to <br />
                      <span className="text-blue-600">
                        Professional Coaching Online
                      </span>
                    </h2>

                    <button
                      onClick={closeModal}
                      className="cursor-pointer text-gray-600 hover:text-gray-800 flex items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-6 h-6 inline-block mr-1"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div class="flex flex-col mt-5 items-center">
                    <button
                      onClick={handleGoogleSignIn}
                      class="w-full max-w-2xl border border-gray-400 font-bold shadow-sm rounded-lg py-3 bg-white text-black flex items-center justify-center transition-all duration-300 ease-in-out focus:outline-none hover:shadow focus:shadow-lg focus:shadow-outline"
                    >
                      <div class="bg-white p-2 rounded-full">
                        <svg class="w-4" viewBox="0 0 533.5 544.3">
                          <path
                            d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                            fill="#4285f4"
                          />
                          <path
                            d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                            fill="#34a853"
                          />
                          <path
                            d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                            fill="#fbbc04"
                          />
                          <path
                            d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                            fill="#ea4335"
                          />
                        </svg>
                      </div>
                      <span class="ml-4">Sign in with Google</span>
                    </button>
                  </div>
                  <div class="my-6 border-b text-center">
                    <div class="leading-none px-2 inline-block text-sm text-gray-900 tracking-wide font-medium bg-white transform translate-y-1/2">
                      Or sign up with phone
                    </div>
                  </div>

                  <form action="#" class="mt-8 grid grid-cols-6 gap-6">
                    <div class="col-span-6 sm:col-span-3 mt-2">
                      <label
                        for="FirstName"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Country Code
                      </label>
                      <select
                        onChange={(e) => {
                          setcountryCode(e.target.value);
                        }}
                        value={countryCode}
                        data-te-select-filter="true"
                        className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                      >
                        {countryCodeList.map((doc, index) => {
                          return (
                            <option
                              value={doc.dial_code}
                              data-te-select-secondary-text="Secondary text"
                            >
                              {doc.dial_code}&nbsp; ({doc.name})
                            </option>
                          );
                        })}
                      </select>
                    </div>{" "}
                    <div class="col-span-6 sm:col-span-3 mt-2">
                      <label
                        for="FirstName"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Phone Number
                      </label>
                      <input
                        className={
                          validationError
                            ? "w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none "
                            : "w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-red-500 focus:bg-white focus:outline-none "
                        }
                        type="tel"
                        value={phoneNumber}
                        onChange={(e) => {
                          setPhoneNumber(e.target.value);
                        }}
                        placeholder="Enter your phone number"
                      />
                    </div>
                    {smsFlag ? (
                      <div class="col-span-6">
                        <label
                          for="Email"
                          class="block text-sm font-medium text-gray-700"
                        >
                          OTP Code
                        </label>

                        <input
                          className="w-full px-4 py-3 rounded-lg bg-white mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                          type="text"
                          value={otpCode}
                          onChange={(e) => setOTPCode(e.target.value)}
                          placeholder="Enter the received OTP code"
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <div
                            style={{
                              marginRight: "10px",
                              color: timerFlag ? "gray" : "balck",
                            }}
                          >
                            <button
                              disabled={timerFlag}
                              style={{
                                fontWeight: font,
                                color: color,
                              }}
                              onClick={() => {
                                handleSignOut();
                                startTimer();
                              }}
                            >
                              Resend OTP
                            </button>
                          </div>
                          <div>
                            <p
                              className="text-bold"
                              style={{ color: "black", fontSize: "15px" }}
                            >
                              {seconds + 1} seconds
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div class="col-span-6 sm:flex justify-center sm:items-center sm:gap-4">
                      {smsFlag ? (
                        <button
                          onClick={verify}
                          className="w-full max-w-2xl relative inline-flex items-center justify-center px-4 py-4  font-medium tracking-tighter text-white bg-blue-500 rounded-lg group "
                        >
                          <span className="relative">Login</span>
                        </button>
                      ) : (
                        <button
                          onClick={onSignInSubmit}
                          className="w-full max-w-2xl relative mt-1 inline-flex items-center justify-center px-4 py-4  font-medium tracking-tighter text-white bg-blue-500 rounded-lg group "
                        >
                          <span className="relative">Send OTP</span>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
          <div
            class="pointer-events-auto mx-auto mb-4 hidden w-96 max-w-full rounded-lg bg-danger-100 bg-clip-padding text-sm text-danger-700 shadow-lg shadow-black/5 data-[te-toast-show]:block data-[te-toast-hide]:hidden"
            id="static-example"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-te-autohide="false"
            data-te-toast-init
            data-te-toast-show
          ></div>
          <div id="recaptcha-widget" />
    </div>
  );
}
