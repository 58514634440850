import React from "react";
import { PieChart, pieArcClasses } from "@mui/x-charts/PieChart";
import { useLocation } from "react-router-dom";
import img from './assets/fa.jpg'
import './css/radio.css'
export default function ExamResult() {
    const location = useLocation();
  const data = [
    { id: 0, value: parseInt(location.state.marks), label: "Correct" },
    {
      id: 1,
      value: Math.abs(
        parseInt(location.state.wrong)
      ),
      label: "Wrong",
    },
    { id: 2, value: parseInt(location.state.unatt), label: "Skip" },
  ];
  const palette = ["yellow", "black", "gray"];
  const size = {
    height: 360,
  };
  const success_img="https://img.freepik.com/free-vector/employee-appreciation-concept-illustration_114360-16448.jpg?w=740&t=st=1691815707~exp=1691816307~hmac=544292e54bf66322b540130cd3a0c9b62d5d12b7bd0f256714487ca3bac0b9af";
  return (
    <div className="section">
      <section class="py-10 bg-white sm:py-16 lg:py-4">
        <div className="space-y-2 text-center py-12">
          <h2 className="text-4xl font-bold py-2">Your Exam Result</h2>
          <p className="font-serif text-xl dark:text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div>
        <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div class="grid items-center grid-cols-1 gap-y-8 lg:grid-cols-2 gap-x-16 xl:gap-x-24">
            <div class="relative mb-12">
             
              <img
                class="w-full rounded-md animate-jump animate-once animate-ease-out"
                src={parseInt(location.state.marks) >
                      parseInt(location.state.data.length) / 2 ?success_img:img}
                alt=""
              />

              <div class="absolute w-full max-w-xs px-4 -translate-x-1/2 sm:px-0 sm:max-w-sm left-1/2 -bottom-12">
                <div class="overflow-hidden bg-gray rounded">
                  <div class="px-10 py-6">
                    <div class="flex items-center">
                      <p class="flex-shrink-0 text-3xl font-bold text-blue-600 sm:text-4xl">
                        {location.state.marks}/{location.state.data.length}
                      </p>
                      {parseInt(location.state.marks) >
                      parseInt(location.state.data.length) / 2 ? (
                        <p class="pl-6 text-sm font-extrabold text-green-800 sm:text-lg">
                          <>EXCELLENT</>
                        </p>
                      ) : (
                        <p class="pl-6 text-sm font-extrabold text-red-800 sm:text-lg">
                          <>NEED TO IMPROVE</>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <PieChart
                colors={palette}
                series={[
                  {
                    data,
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: { innerRadius: 30, additionalRadius: -30 },
                  },
                  
                ]}
                sx={{
                  [`& .${pieArcClasses.faded}`]: {
                    fill: "gray",
                  },
                }}
                
                {...size}
              />
            </div>
          </div>
        </div>
        <div className="space-y-2 text-center py-12">
          <h2 className="text-4xl font-bold py-2">Answers</h2>
        </div>

        {location.state.data.map((doc, index) => {
          return (
            <div class="container mx-auto py-8">
              <div class="max-w-6xl mx-auto bg-white shadow-lg rounded-lg p-8">
                <h2 class="text-xl font-semibold mb-4">
                  Question &nbsp;{index + 1}:
                </h2>
                <p class="text-gray-600 mb-6 xl:mr-10 sm:mr-5">
                  {doc.data.question}
                </p>
                <ul class="space-y-2">
                  {doc.ans === doc.data.a ? (
                    <>
                      <li class="flex items-center">
                        <label
                          for="option1"
                          class="ml-2 text-gray-700"
                          style={
                            doc.data.answer === doc.data.a
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          Option A : {doc.data.a}
                        </label>
                      </li>
                    </>
                  ) : (
                    <>
                      <li class="flex items-center">
                        <label
                          for="option1"
                          class="ml-2 text-gray-700"
                          style={
                            doc.data.answer === doc.data.a
                              ? { color: "green" }
                              : null
                          }
                        >
                          Option A : {doc.data.a}
                        </label>
                      </li>
                    </>
                  )}
                  {doc.ans === doc.data.b ? (
                    <>
                      <li class="flex items-center">
                        <label
                          for="option1"
                          class="ml-2 text-gray-700"
                          style={
                            doc.data.answer === doc.data.b
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          Option B : {doc.data.b}
                        </label>
                      </li>
                    </>
                  ) : (
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.b
                            ? { color: "green" }
                            : null
                        }
                      >
                        Option B : {doc.data.b}
                      </label>
                    </li>
                  )}
                  {doc.ans === doc.data.c ? (
                    <>
                      <li class="flex items-center">
                        <label
                          for="option1"
                          class="ml-2 text-gray-700"
                          style={
                            doc.data.answer === doc.data.c
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          Option C : {doc.data.c}
                        </label>
                      </li>
                    </>
                  ) : (
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.c
                            ? { color: "green" }
                            : null
                        }
                      >
                        Option C : {doc.data.c}
                      </label>
                    </li>
                  )}
                  {doc.ans === doc.data.d ? (
                    <>
                      <li class="flex items-center">
                        <label
                          for="option1"
                          class="ml-2 text-gray-700"
                          style={
                            doc.data.answer === doc.data.d
                              ? { color: "green" }
                              : { color: "red" }
                          }
                        >
                          Option D : {doc.data.d}
                        </label>
                      </li>
                    </>
                  ) : (
                    <li class="flex items-center">
                      <label
                        for="option1"
                        class="ml-2 text-gray-700"
                        style={
                          doc.data.answer === doc.data.d
                            ? { color: "green" }
                            : null
                        }
                      >
                        Option D : {doc.data.d}
                      </label>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          );
        })}
      </section>
    </div>
  );
}
