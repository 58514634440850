import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "../assets/Loading";
import { app, db } from "../Config/Config";
import Package from "../Package/Package";
import Popup from "reactjs-popup";
import PurchasePopup from "./PreBuy/PurchasePopup";

export default function Bookdetails(user) {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [bookData,setbookData] = useState();
  const [openPopup, setOpenPopup] = useState(true);
  const [bookDetails, setbookDetails] = useState();
  const [prePurchasePopup, setprePurchasePopup] = useState(false);


  useEffect(() => {
    updateData();
  }, []);

  const [userId, setuserId] = useState(null);
  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setuserId(user.uid);
  }, []);



  // payment function calling
  const functions = getFunctions(app, "asia-south1");

  const paymentFunction = () => {
    setloading(true);
    setprePurchasePopup(false);
    const paymentRequest = httpsCallable(functions, "createOrder");
    paymentRequest({
      img: bookData.book_img,
      package: location.state.book_id,
      name: bookData.book_name,
      uid: userId,
      platform: "web",
      books: [location.state.book_id],
      collection: "book_pricing",
      currency: user.country === "India" ? "INR" : "USD",
      amount:
        user.country === "India"
          ? bookData.offer_price
          : bookData.dollar_offer_price,
    }).then((result) => {
      const data = result.data;
      if (data !== 500) {
        const order = result.data;

        const options = {
          key: process.env.REACT_APP_RAZOR_KEY,
          amount: order.amount,
          currency: order.currency,
          name: "The Goodwill Academy",
          description: "Payment for e-book",
          order_id: order.id,
          handler: function (response) {
            setloading(false);
          },
          prefill: {
            name: order.notes.user_name,
            email: getAuth().currentUser.email,
            contact: order.notes.mobile,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
          modal: {
            ondismiss: function () {
              setloading(false);
            },
          },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          setloading(false);
          alert("Something went wrong! Try again..");
        });
      } else {
        alert("Something went wrong!!");
        setloading(false);
      }
    });

    //========================================= Stripe payment function =========================================//

    //  const paymentRequest = httpsCallable(functions, "paymentRequest");
    //  paymentRequest({
    //    img: bookData.book_img,
    //    package: location.state.book_id,
    //    name: bookData.book_name,
    //    uid: userId,
    //    platform: "web",
    //    books: [location.state.book_id],
    //    collection: "book_pricing",
    //    // currency: user.country === "India" ? "inr" : "usd",
    //    currency: "inr",
    //  }).then((result) => {
    //    const data = result.data;
    //    if (data) {
    //      window.location.href = data.url;
    //    } else {
    //      alert("Something went wrong!!");
    //      setloading(false);
    //    }
    //  });
    //========================================= Stripe payment function =========================================//
  };

  const updateData = async () => {
    const docRef = doc(db, "book_intro", location.state.book_id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setbookDetails(docSnap.data());
        setloading(false);
     
    }
    const docRef2 = doc(db, "book_pricing", location.state.book_id);
    const docSnap2 = await getDoc(docRef2);
    if (docSnap2.exists()) {
        setbookData(docSnap2.data());
        setloading(false);
      }
  };
  const [chapterList, setchapterList] = useState([]);
  const navigate = useNavigate();

  // fetch books

  const fetchBooks = async () => {
    setchapterList([]);
    const q = query(
      collection(db, "chapters"),
      orderBy("order", "asc"),
      where("status", "==", true),
      where("book_id", "==", location.state.book_id)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setchapterList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  const [flag, setflag] = useState(true);
  useState(() => {
    fetchBooks();

    if (user && user.my_books.includes(location.state.book_id)) {
      setflag(true);
    } 
  
  });
  const divStyle = {
    cursor: "pointer",
    // Add any other styles you want for the div
  };

  useState(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    setuserId(user.uid);
   //  fetchProgress(user.uid);
  });
  window.addEventListener("bookdetailsloading", (event) => {
    if (event.type === "bookdetailsloading") {
        setloading(localStorage.getItem("bookdetailsloading"));
    }
  });
  const handleClose = () => {
    // window.location.reload();
    setprePurchasePopup(false);
  };

  const handlePopupSubmit = () => {
    paymentFunction();
  };
  const handleprePurchase = () => {
      setprePurchasePopup(true);
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <section className="dark:bg-white dark:text-black-800">
          {prePurchasePopup ? (
            <PurchasePopup
              onClose={handleClose}
              onSubmit={handlePopupSubmit} // Pass the callback to handle return value
            />
          ) : null}
          {bookDetails ? (
            <div className="container max-w-xl p-6 py-12 mx-auto space-y-24 lg:px-8 lg:max-w-7xl">
              {location.state.page === "/exams" ? null : (
                <div>
                  <h2 className="text-2xl font-bold track text-center sm:text-5xl dark:text-black-50">
                    {bookDetails.book_name}
                  </h2>
                  <p className="text-lg mx-auto mt-4  text-center dark:text-gray-400">
                    {" "}
                    {bookDetails.short_description}
                  </p>
                </div>
              )}

              {/* ith	 */}
              <div class="relative overflow-hidden">
                <div class="max-w-[85rem] px-4 py-8 sm:px-4  lg:px-8 lg:py-4 mx-auto">
                  {location.state.page === "/exams" ? null : (
                    <div class="lg:grid lg:grid-cols-6 lg:gap-8 lg:items-center  ">
                      <div class=" lg:block lg:col-span-2 ">
                        <img
                          class="rounded-xl"
                          src={location.state.img}
                          alt="a"
                        />
                      </div>
                      <div class="lg:col-span-4 sm:col-span-12">
                        <blockquote>
                          <p class="text-lg font-medium text-black lg:text-xl lg:leading-normal dark:text-black">
                            {bookDetails.intro}
                          </p>
                        </blockquote>
                        {user && user.my_books.includes(location.state.book_id) ? null : (
                          <>
                            <button
                              onClick={handleprePurchase}
                              class="mt-6 px-8 py-2 rounded-lg bg-black px-4 py-1.5 text-white duration-100 hover:bg-black"
                            >
                              Buy Now
                            </button>
                            {flag ? (
                              <button
                                onClick={() => setflag(!flag)}
                                class="mt-6 px-8 py-2 rounded-lg  px-4 py-1.5 text-black duration-100 "
                              >
                                Show less
                              </button>
                            ) : (
                              <button
                                onClick={() => setflag(!flag)}
                                class="mt-6 px-8 py-2 rounded-lg  px-4 py-1.5 text-black duration-100 "
                              >
                                Show more
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                  <ul class=" dark:bg-white dark:text-black">
                    {flag ? (
                      <>
                        {chapterList ? (
                          <>
                            {chapterList.length > 0 ? (
                              <div className="space-y-2 py-4 text-center">
                                <h2 className="text-3xl py-4 font-bold">
                                  Chapters
                                </h2>
                              </div>
                            ) : (
                              <div className="space-y-2 py-4 text-center">
                                <h2 className="text-3xl py-4 font-bold">
                                  Please wait. chapters will soon be updated
                                </h2>
                              </div>
                            )}

                            {chapterList.map((doc, index) => {
                              return (
                                <li className="mb-4">
                                  <article>
                                    <button
                                      style={divStyle}
                                      onClick={() => {
                                        if (user && user.my_books.includes(location.state.book_id)) {
                                          navigate("/chapter", {
                                            state: {
                                              data: doc,
                                              id: doc.id,
                                              book: bookDetails,
                                              page:
                                                location.state.page === "/exams"
                                                  ? "exam"
                                                  : "nil",
                                            },
                                          });
                                        } else {
                                          handleprePurchase();
                                        }
                                      }}
                                      class=" w-full  rounded-lg border border-black p-0.5 shadow-lg"
                                    >
                                      <div class="bg-white p-7 rounded-md">
                                        <h1 class="font-bold text-xl mb-2">
                                          {index + 1}&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                                          {doc.data.chapter_name}
                                        </h1>
                                        <p> {doc.data.desc}</p>
                                      </div>

                                      {location.state.page ===
                                      "/exams" ? null : (
                                        <>
                                          <p class="row-start-1 mb-1 mt-4 md:col-start-1 xl:col-span-2 dark:text-black text-2xl font-extrabold "></p>
                                          {user && user.my_books.includes(location.state.book_id) ? null : (
                                            <img
                                              className="ml-6"
                                              width="50"
                                              height="50"
                                              src="https://img.icons8.com/ios-filled/50/lock-2.png"
                                              alt="lock-2"
                                            />
                                          )}
                                        </>
                                      )}
                                    </button>
                                  </article>
                                </li>
                              );
                            })}
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>
            </div>
          ) : null}
          {user && user.my_books.includes(location.state.book_id) ? null : (
            <>
              {openPopup ? (
                <Popup
                  open={openPopup}
                  onClose={() => setOpenPopup(false)}
                  contentStyle={{
                    background: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
                    backdropFilter: "blur(10px)", // Add a blur effect
                    border: "none",
                    width: "100%", // Full width
                    height: "100%",
                    overflow: "auto",
                    padding: 0,
                  }}
                >
                  {(close) => (
                    <div className="p">
                      <button
                        class=" ml-auto float-right mr-8 mt-8"
                        onClick={close}
                      >
                        <img
                          width="32"
                          height="32"
                          src="https://img.icons8.com/material-outlined/24/delete-sign.png"
                          alt="delete-sign"
                          class="ml-2"
                        />
                      </button>

                      <Package />
                    </div>
                  )}
                </Popup>
              ) : null}
            </>
          )}
        </section>
      )}
    </>
  );
}
