import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";
import { db } from "../Config/Config";
import placeHolder from "./Images/web book tumb.png";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Purchased() {
  AOS.init();
  const [bookList, setbookList] = useState([]);
  const [imageFlag, setimageFlag] = useState(true);

  const navigate = useNavigate();

  // fetch books

  const fetchBooks = async (n) => {
    setbookList([]);
    const q = query(
      collection(db, "book_pricing"),
      where("status","==",true),
      orderBy("order", "asc"),
      limit(n)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setbookList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  useState(() => {
  
      fetchBooks(16);
  
  });
  const divStyle = {
    cursor: "pointer",
  };

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        purchaseCheck(user.uid);
      } 
    });
  }, []);
  const [pBooks, setpBooks] = useState();
  const purchaseCheck = async (uid) => {
    const userRef = doc(db, "users", uid);
    await getDoc(userRef).then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        setpBooks(data);
      } else {
        console.log("Document not found");
      }
    });
  };
 

  return (
    <div>
      <section
   
      >
        <div class="mx-auto grid max-w-8xl  grid-cols-1 gap-6 p-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {pBooks ? (
            <>
            
              {bookList.map((doc, index) => {
                return (
                  <>
                    {pBooks &&
                    pBooks.my_books.includes(doc.data.book_id) ? (
                      <article
                        style={divStyle}
                        onClick={() => {
                          navigate("/bookdetails", {
                            state: {
                              book_id: doc.data.book_id,
                              status: true,
                              img: doc.data.book_img,
                            },
                          });
                        }}
                        class="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 "
                      >
                        <div class="relative flex items-end overflow-hidden rounded-xl">
                          <img
                            onLoad={() => setimageFlag(false)}
                            src={imageFlag ? placeHolder : doc.data.book_img}
                            alt="aa"
                          />
                        </div>

                        <div class="mt-1 p-2">
                          <h2 class="text-slate-700 text-sm">
                            {doc.data.book_name.toUpperCase()}
                          </h2>
                          <p class="mt-1 text-sm text-slate-400">
                            {doc.data.sub_title}
                          </p>

                          <div class="mt-2 flex items-end justify-between">
                            <div class="flex items-center space-x-1.5 rounded-lg bg-black hover:bg-black px-4 py-1.5 text-white duration-100 ">

                              <button class="text-sm">Read Now</button>
                            </div>
                          </div>
                        </div>
                      </article>
                    ) : null}
                  </>
                );
              })}
            </>
          ) : null}
        </div>
      </section>
    </div>
  );
}
