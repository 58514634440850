import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import User from "../Auth/User";
import UserData from "../Config/UserData";
import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../Config/Config";
import male from "./assets/male.png";
import female from "./assets/Female.png";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
const formatDate = (timestamp) => {
  return new Date(timestamp * 1000).toLocaleDateString();
};

const ProfileItem = ({ label, value }) => (
  <div className="flex flex-col sm:flex-row py-2">
    <div className="sm:w-1/3 text-gray-500">{label}</div>
    <div className="sm:w-2/3">{value}</div>
  </div>
);

export default function Profile() {
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const uid = User();
    const [avatar, setAvatar] = useState(null);

  const userData = UserData(uid);

  useEffect(() => {
    setUser(userData);
    if(userData){
      if(userData.profileImg){
        setAvatar(userData.profileImg);
      } else if(userData.gender==="male") {
        setAvatar(male);
      } else if(userData.gender==="female"){
         setAvatar(female);
      }
    }
  }, [userData]);

  const auth = getAuth();
  const [purchaseList, setpurchaseList] = useState([]);
  //======================================================== List Fetching =========================================================//
  const fetchList = async (uid) => {
    const q = query(
      collection(db, "purchase_history"),
      where("uid", "==", uid),
      orderBy("subscribed_date", "desc"),
      limit(10)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      setpurchaseList((prev) => {
        return [
          ...prev,
          {
            id: doc.id,
            data: doc.data(),
          },
        ];
      });
    });
  };
  //======================================================== User Id =========================================================//

  useState(() => {
    fetchList(auth.currentUser.uid);
  });

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const storage = getStorage();
      if (file) {
        const storageRef = ref(storage, "profile-picture/" + user.uid+"/"+file.name);
        uploadBytes(storageRef, file).then((snapshot) => {
          getDownloadURL(ref(storageRef)).then(async(url) => {
            await updateDoc(doc(db, "users", user.uid), {
              profileImg:url,
            });
            setAvatar(url);
          });
        });
      } 
  };

  return (
    <div className="p-4 bg-gray-100 min-h-screen">
      <div className="mx-auto w-full max-w-4xl">
        <div className="bg-white shadow-md rounded-lg overflow-hidden mt-6 relative">
          <button
            onClick={() => navigate("/editprofile")}
            className="absolute top-4 right-4 bg-blue-500 text-white px-4 py-2 rounded-md flex items-center space-x-1 hover:bg-blue-600"
          >
            <svg
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1 3.33l3.57 3.57c.39.39.39 1.02 0 1.41l-10 10c-.2.2-.45.3-.71.3s-.51-.1-.71-.3l-3.57-3.57c-.39-.39-.39-1.02 0-1.41l10-10c.39-.39 1.02-.39 1.41 0zm1.17-.75c-1.06-1.06-2.78-1.06-3.84 0l-10 10c-.44.44-.67 1.02-.67 1.62v4c0 1.1.9 2 2 2h4c.6 0 1.18-.23 1.62-.67l10-10c1.06-1.06 1.06-2.78 0-3.84l-3.57-3.57z"
                fill="currentColor"
              />
            </svg>
            <span>Edit Profile</span>
          </button>
          <div className="border-b border-gray-200 p-6">
            <div className="flex items-center">
              <div className="flex items-center space-x-4 mr-6">
                <label htmlFor="avatarInput">
                  {userData.gender === "male" ? (
                    <div className="relative">
                      <img
                        className="w-30 h-30 rounded-full"
                        width="140"
                        height="10"
                        src={avatar}
                        alt="circled-user-male-skin-type-3--v1"
                      />
                      <div className="absolute inset-0 flex items-center justify-center bg-gray-600 opacity-0 hover:opacity-50 transition-opacity rounded-full">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-upload"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                      </div>
                    </div>
                  ) : (
                    <>
                      {userData && userData.gender === "female" ? (
                        <div className="relative">
                          <img
                            className="w-30 h-30 rounded-full"
                            width="140"
                            height="10"
                            src={avatar}
                            alt="circled-user-male-skin-type-3--v1"
                          />
                          <div className="absolute inset-0 flex items-center justify-center bg-gray-600 opacity-0 hover:opacity-50 transition-opacity rounded-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-upload"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                              <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                            </svg>
                          </div>
                        </div>
                      ) : null}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  accept="image/*"
                  id="avatarInput"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
              </div>

              <div>
                <h2 className="text-2xl font-semibold text-gray-800">
                  {user?.name}
                </h2>
                <p className="text-gray-600">{user?.email}</p>
              </div>
            </div>
          </div>
          <div className="p-6">
            <div className="border-b border-gray-200 pb-4 mb-4">
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Personal Information
              </h3>
              <div className="space-y-2">
                <ProfileItem
                  label="Enrollment ID"
                  value={user?.enrollement_id}
                />
                <ProfileItem
                  label="Date of Birth"
                  value={formatDate(user?.dob?.seconds)}
                />
                <ProfileItem
                  label="Country"
                  value={user?.country?.toUpperCase()}
                />
                <ProfileItem
                  label="State/Province"
                  value={user?.state?.toUpperCase()}
                />
                <ProfileItem
                  label="District"
                  value={user?.district?.toUpperCase()}
                />
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800 mb-2">
                Contact Information
              </h3>
              <div className="space-y-2">
                <ProfileItem label="Contact Number" value={user?.mobile} />
                {user?.email && (
                  <ProfileItem label="Email" value={user?.email} />
                )}
              </div>
            </div>

            <div className="border-b border-gray-200 pb-4 mb-4"></div>
            <h3 className="text-lg font-semibold text-gray-800 ">
              Payment History
            </h3>
            <div className="bg-white rounded-lg overflow-hidden">
              <div className="p-4 border-b border-gray-200">
                {purchaseList.length > 0 ? (
                  <div className="divide-y divide-gray-200">
                    {purchaseList.map((purchase, index) => (
                      <div
                        key={index}
                        className="py-3 flex items-center justify-between"
                      >
                        <p className="text-lg font-semibold">
                          {purchase.data.book_details ? (
                            purchase.data.book_details.map((doc) => {
                              return doc.book_name + ",";
                            })
                          ) : (
                            <>{purchase.data.package.toUpperCase()}</>
                          )}
                        </p>
                        <p className="text-sm text-gray-500">
                          Date:{" "}
                          {purchase.data.subscribed_date
                            ? new Date(
                                purchase.data.subscribed_date.seconds * 1000
                              ).toLocaleDateString()
                            : "Date Not Available"}
                        </p>

                        <div className="flex items-center">
                          <button
                            onClick={() => {
                              navigate("/invoice", {
                                state: {
                                  data: purchase.data,
                                },
                              });
                            }}
                            className="ml-4 text-black hover:text-black focus:outline-none"
                          >
                            View Invoice
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 p-4">
                    No purchase history available.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}