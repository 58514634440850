import React, { useState, useEffect } from "react";
import placeHolder from "../Book/Images/web book tumb.png";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../Config/Config";
import { useNavigate } from "react-router-dom";

export default function Studies() {
  const navigate = useNavigate();
  const [levelList, setLevelList] = useState([]);
  const [imageFlag, setImageFlag] = useState(true);

  useEffect(() => {
    const fetchLevel = async () => {
      const q = query(collection(db, "levels"), orderBy("order", "asc"), where("status", "==", true));

      const querySnapshot = await getDocs(q);
      const levels = [];
      querySnapshot.forEach((doc) => {
        levels.push({
          id: doc.id,
          data: doc.data(),
          showFullDescription: false, // Initialize showFullDescription state
        });
      });
      setLevelList(levels);
    };

    fetchLevel();
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const toggleDescription = (index) => {
    setLevelList((prevLevels) => {
      const updatedLevels = [...prevLevels];
      updatedLevels[index].showFullDescription = !updatedLevels[index].showFullDescription;
      return updatedLevels;
    });
  };

  const divStyle = {
    cursor: "pointer",
  };

  return (
    <div>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <h2 className="mt-4 text-2xl font-bold text-black dark:text-black md:text-4xl py-6">
          Navigating Today's Accounting Landscape: Tailored ACCA Education for Your
          <br className="sm:block" hidden />
          Finance & Accounting Profession
        </h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 py-12">
          {levelList.map((doc, index) => {
            const description = doc.data.short_description;
            const truncatedDescription = description.split(" ").slice(0, 30).join(" ");
            const showFullDescription = doc.showFullDescription;

            return (
              <div
                key={index}
                style={divStyle}
                className="group rounded-xl overflow-hidden dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={() => {
                  navigate("/acca-levels/levelbooks", {
                    state: {
                      level: doc.data.level_id,
                      name: doc.data.level_name,
                    },
                  });
                }}
              >
                <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
                  <img
                    onLoad={() => setImageFlag(false)}
                    className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
                    src={imageFlag ? placeHolder : doc.data.level_img}
                    alt="Image"
                  />
                  {doc.data.tag && (
                    <span className="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-blue-600 text-white py-1.5 px-3 dark:bg-blue-600">
                      {doc.data.tag}
                    </span>
                  )}
                </div>

                <div className="mt-7">
                  <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-800">
                    {doc.data.level_name}
                  </h3>

                 <p className="mt-3 text-gray-800 dark:text-gray-800">
  {showFullDescription ? (
    description.split("\n").map((paragraph, index) => (
      <span key={index}>
        {paragraph}
        <br />
      </span>
    ))
  ) : (
    truncatedDescription
  )}
  {description.split(" ").length > 30 && (
    <button
      onClick={(e) => {
        e.stopPropagation();
        toggleDescription(index); // Toggle full description for this item
      }}
      className="ml-2 text-md text-blue-600"
    >
      {showFullDescription ? "Read Less" : "Read More"}
    </button>
  )}
</p>

                 
                 
                </div>
                {/* <p className="mt-2 inline-flex items-center gap-x-1  text-blue-400  rounded-xl group">
                    <span className="font-medium">Start Now</span>
                  </p> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
